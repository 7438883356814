.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shakeAnimation {
  0%, 20%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(-5deg); }
  30% { transform: rotate(5deg); }
  35% { transform: rotate(-5deg); }
  40% { transform: rotate(5deg); }
  45% { transform: rotate(0deg); }
}

/* App.css */
.feedback-btn {
  position: fixed;
  bottom: 70px; /* Высота от нижней границы экрана */
  right: 20px; /* Отступ справа */
  z-index: 1000; /* Убедитесь, что кнопка будет над другими элементами */
  animation: shakeAnimation 5s ease-in-out infinite;
}
